import React, {
  ChangeEvent,
  FormEvent,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  Grid,
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  LinearProgress,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloudUploadOutlined from "@mui/icons-material/CloudUploadOutlined";
import { v4 as uuidv4 } from "uuid";
import DragAndDrop from "../components/uploader//DragAndDrop";
import { useApi } from "../hooks/useApi";
import IconTooltip from "../components/uploader/IconTooltip";
import InfoSnackbar from "../components/uploader/InfoSnackbar";

import { FormField, UserFile } from "../types";
import {
  Folder,
  Notes,
  AddLink,
  UploadFile,
  ModeEdit,
} from "@mui/icons-material";

import { CDN_IMAGES } from "../util/images";
import UploadSuccessDialog from "../components/uploader/UploadSuccessDialog";
import DatasetNameDialog from "../components/uploader/DatasetNameDialog";
import { DatasetState, MediaType } from "../openapi/api";
import { file } from "jszip";

const MAX_FILESIZE = 1024 * 1024 * 30; // 30 MB
const FILE_CHUNK_SIZE = 1024 * 1024 * 2; // 2 MB

interface UploadViewProps {
  isOnline: boolean;
  getKID: () => string;
}

interface UploadCache {
  datasetID: string;
  fileName: string;
  fileID: string;
  chunkIndex: number;
}

export const UploadView = (props: UploadViewProps) => {
  const {
    createDataset,
    createFile,
    getDatasetById,
    findFileByDatasetIdAndFileId,
    finishDataset,
    appendToFile,
  } = useApi();
  const [userFiles, setUserFiles] = useState<UserFile[]>([]);
  const [showDatesetNameDialog, setShowDatesetNameDialog] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const progressCounterRef = useRef(0);
  const [progressText, setProgressText] = useState<string>("Creating dataset");
  // using a ref, because props.isOnline might be stale and slow, which affects the online / offline logic in uploadFileChunked
  const isOnlineRef = useRef(props.isOnline);

  const [formData, setFormData] = useState<Record<FormField, string>>({
    file: "",
    datasetName: "",
    comment: "",
    reference: "",
  });

  const [formErrors, setFormErrors] = useState<Record<FormField, string>>({
    file: "",
    datasetName: "",
    comment: "",
    reference: "",
  });

  const [uploadCache, setUploadCache] = useState<UploadCache>({
    datasetID: "",
    fileName: "",
    fileID: "",
    chunkIndex: 0,
  });

  const setUserFileUploaded = (id: string) => {
    setUserFiles((prevUserFiles) =>
      prevUserFiles.map((userFile) =>
        userFile.id === id ? { ...userFile, isUploaded: true } : userFile,
      ),
    );
  };

  const handleCommentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData((formData) => ({
      ...formData,
      comment: event.target.value,
    }));
  };

  const handleReferenceChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFormData((formData) => ({
      ...formData,
      reference: event.target.value,
    }));
  };

  const handleDeleteFile = (fileId: string | undefined) => {
    if (fileId) {
      setUserFiles((userFiles) =>
        userFiles.filter((file) => file.id !== fileId),
      );

      if (userFiles.length == 0) {
        setFormData({ ...formData, datasetName: "" });
      }
    } else {
      setUserFiles([]);
      setFormData({ ...formData, datasetName: "" });
    }
  };

  const validateForm = () => {
    const newFormErrors: Record<FormField, string> = {
      file: "",
      datasetName: "",
      reference: "",
      comment: "",
    };

    if (formData.comment && formData.comment.length > 100) {
      newFormErrors.reference =
        "Dataset comment cannot be longer than 100 characters";
    }

    if (formData.reference && formData.reference.length > 100) {
      newFormErrors.reference =
        "Dataset reference cannot be longer than 20 characters";
    }

    if (!formData.datasetName) {
      newFormErrors.datasetName = "Dataset name is required";
    }

    if (
      formData.datasetName &&
      !/^[a-zA-Z0-9_ .-]{0,512}$/.test(formData.datasetName)
    ) {
      newFormErrors.datasetName = "No special or more than 50 characters";
    }

    if (userFiles.length == 0) {
      newFormErrors.file = "You need to select a file";
    }

    setFormErrors(newFormErrors);

    // return true if one value in newFormErrors is !== ""
    return !(Object.keys(newFormErrors) as Array<FormField>).some(
      (key) => newFormErrors[key] !== "",
    );
  };

  const uploadCleanup = () => {
    setUploadSuccess(false);
    setUserFiles([]);
    setFormData({
      file: "",
      datasetName: "",
      comment: "",
      reference: "",
    });
    setUploadCache({
      datasetID: "",
      fileName: "",
      fileID: "",
      chunkIndex: 0,
    });
    setProgress(0);
    progressCounterRef.current = 0;
    setIsUploading(false);
    setProgressText("Creating dataset");
    setErrorMessage("");
  };

  const updateProgress = (totalProgressSteps: number) => {
    const percentage = (100 / totalProgressSteps) * progressCounterRef.current;
    setProgress(percentage);
  };

  useEffect(() => {
    isOnlineRef.current = props.isOnline;
    if (props.isOnline && isUploading) {
      uploadUserFiles();
    }
  }, [props.isOnline]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm()) {
      // scroll to bottom of page, so user sees the progressbar
      window.scrollTo(0, document.body.scrollHeight);
      uploadUserFiles();
    }
  };

  const calculateProgressSteps = (files: UserFile[]) => {
    // calculate total progress steps
    let totalProgressSteps = 0;
    files.map((userFile) => {
      if (userFile.file.size <= MAX_FILESIZE) {
        totalProgressSteps += 1;
      } else {
        const numChunks = userFile.file.size / FILE_CHUNK_SIZE;
        totalProgressSteps += numChunks;
      }
    });

    return totalProgressSteps;
  };

  const createNewDataset = async () => {
    if (uploadCache.datasetID === "") {
      const response = await createDataset(
        formData.datasetName,
        formData.reference,
        formData.comment,
      );

      if (response && response.status === 201) {
        setUploadCache({
          ...uploadCache,
          datasetID: response.data["id"],
        });

        return response.data["id"];
      } else {
        setShowError(true);

        setErrorMessage(`Could not create dataset: ${response?.data}`);
      }
    } else {
      return uploadCache.datasetID;
    }
  };

  const uploadFile = async (
    datasetID: string,
    fileName: string,
    mediaType: MediaType,
    file: File,
  ) => {
    // check if file was already successfully upload during connection loss
    if (fileName === uploadCache.fileName) {
      const response = await findFileByDatasetIdAndFileId(
        datasetID,
        uploadCache.fileID,
      );

      if (response && response.status === 200) {
        return;
      }
    }

    const response = await createFile(
      datasetID,
      fileName,
      mediaType,
      file,
      undefined,
    );

    if (response?.status !== 201) {
      // application went offline while uploading
      if (!isOnlineRef.current) {
        return {
          datasetID: "",
          fileName: "",
          chunkIndex: 0,
          fileID: response?.data["id"],
        };
      }

      console.error(
        `Error while uploading file: ${fileName} of dataset ${datasetID}: ${response?.data}`,
      );
      setShowError(true);
      setErrorMessage(
        `Error while uploading file: ${fileName} of dataset ${datasetID}: ${response?.data}`,
      );
      return;
    }
  };

  const detectMediaType = (fullFilename: string) => {
    const currentFileNameParts = fullFilename.split(".");
    const currentFileType =
      currentFileNameParts[currentFileNameParts.length - 1].toLowerCase();

    if (["e57", "las"].includes(currentFileType)) {
      return MediaType.PointCloud;
    }
    return MediaType.DroneImage;
  };

  const uploadUserFiles = async () => {
    // ignore if not online
    if (!isOnlineRef.current) {
      return;
    }

    const totalProgressSteps = calculateProgressSteps(userFiles);
    const datasetID = await createNewDataset();

    setIsUploading(true);

    if (datasetID) {
      let tmpUploadCache: UploadCache | undefined;
      for (const userFile of userFiles.filter((file) => !file.isUploaded)) {
        setProgressText("Uploading file " + userFile.file.name);

        if (userFile.file.size >= MAX_FILESIZE) {
          tmpUploadCache = await uploadFileChunked(
            userFile.file,
            datasetID,
            totalProgressSteps,
            detectMediaType(userFile.file.name),
          );
        } else {
          tmpUploadCache = await uploadFile(
            datasetID,
            userFile.file.name,
            detectMediaType(userFile.file.name),
            userFile.file,
          );
        }

        // a file hasn't been uploaded correctly
        if (showError) {
          return;
        }

        // application went offline while uploading
        if (!isOnlineRef.current) {
          if (!tmpUploadCache) {
            tmpUploadCache = {
              datasetID: "",
              fileName: "",
              chunkIndex: 0,
              fileID: "",
            };
          }

          setUploadCache({
            ...uploadCache,
            datasetID: datasetID,
            fileName: userFile.file.name,
            fileID: tmpUploadCache.fileID,
            chunkIndex: tmpUploadCache.chunkIndex,
          });

          return;
        }
        progressCounterRef.current += 1;
        setUserFileUploaded(userFile.id);
        updateProgress(totalProgressSteps);
      }

      if (isOnlineRef.current && !showError) {
        setIsUploading(false);
        setProgressText("Done Uploading");
        const dataset = await getDatasetById(datasetID);
        if (
          dataset &&
          dataset.status === 200 &&
          dataset.data.state == DatasetState.New
        ) {
          await finishDataset(datasetID);
          setUploadSuccess(true);
        } else {
          console.error(
            `Could not finish dataset with id: ${datasetID}: ${dataset?.data}`,
          );
          setShowError(true);
          setErrorMessage(`Could not finish dataset with id: ${datasetID}`);
        }
      }
    }
  };

  const isAllowedFileType = (fullFilename: string) => {
    const allowedFileTypes = ["jpeg", "jpg", "e57", "las"];
    const currentFileNameParts = fullFilename.split(".");
    const currentFileType =
      currentFileNameParts[currentFileNameParts.length - 1].toLowerCase();

    return allowedFileTypes.includes(currentFileType);
  };

  const uploadFileChunked = async (
    file: File,
    datasetId: string,
    totalProgressSteps: number,
    media_type: MediaType,
  ) => {
    const totalSize = file.size;
    const numChunks = totalSize / FILE_CHUNK_SIZE;

    let buffer = 1;
    let chunkIndex = 0;
    let startRange = 0;
    let stopRange = FILE_CHUNK_SIZE;
    let fileID = "";
    let fileChunk = new File([], file.name);
    let contentRange = `bytes ${startRange}-${stopRange}/${totalSize}`;

    // recover from internet connection loss
    if (file.name === uploadCache.fileName) {
      startRange = uploadCache.chunkIndex * FILE_CHUNK_SIZE;
      chunkIndex = uploadCache.chunkIndex;
      fileID = uploadCache.fileID;
      setUploadCache((prevUploadCache) => ({
        ...prevUploadCache,
        fileName: "",
      }));
    }

    // create empty File object in DB to get file_id
    if (fileID === "") {
      const createdFile = await createFile(
        datasetId,
        file.name,
        media_type,
        fileChunk,
        contentRange,
      );
      fileID = createdFile?.data["id"];
    }

    for (let i = chunkIndex; i < numChunks; i += 1) {
      stopRange = startRange + FILE_CHUNK_SIZE;

      if (stopRange >= totalSize) {
        stopRange = totalSize;
        buffer = 0;
      }

      const chunk = file.slice(startRange, stopRange - buffer + 1); // .slice() excludes last index
      fileChunk = new File([chunk], `chunk-${i}-${file.name}`);
      contentRange = `bytes ${startRange}-${stopRange}/${totalSize}`;

      const response = await appendToFile(
        datasetId,
        fileID,
        fileChunk,
        contentRange,
      );

      if (response?.status !== 200) {
        if (!isOnlineRef.current) {
          return {
            datasetID: "",
            fileName: "",
            chunkIndex: i,
            fileID: fileID,
          };
        }
        console.error(
          `Error while chunked uploading file: ${fileID} of dataset ${datasetId}: ${response?.data}`,
        );
        setShowError(true);
        setErrorMessage(
          `Error while chunked uploading file: ${fileID} of dataset ${datasetId}: ${response?.data}`,
        );
        return;
      }

      progressCounterRef.current += 1;
      updateProgress(totalProgressSteps);
      startRange = stopRange;
    }
    return;
  };

  const processEntry = (entry: DataTransferItem, tmpUserFiles: UserFile[]) => {
    const currentFile = entry.getAsFile();

    if (!currentFile) return;

    if (!isAllowedFileType(currentFile.name)) {
      setFormErrors((errors) => ({
        ...errors,
        file: `Only files with the type JPEG, E57 or LAS are allowed not: ${currentFile.name}`,
      }));
      return;
    }

    tmpUserFiles.push({
      id: uuidv4(),
      file: currentFile,
      isUploaded: false,
    });
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));

    const tmpFiles = event.dataTransfer.items;
    const tmpUserFiles: UserFile[] = [];

    if (!tmpFiles) return;

    for (let i = 0; i < tmpFiles.length; i++) {
      const entry = tmpFiles[i].webkitGetAsEntry();

      if (entry?.isFile) {
        processEntry(tmpFiles[i], tmpUserFiles);
      } else if (entry?.isDirectory) {
        setFormData({ ...formData, datasetName: entry.name });

        const reader = (entry as FileSystemDirectoryEntry).createReader();
        reader.readEntries(function (entries) {
          const totalFiles = entries.length;
          let counter = 0;

          entries.forEach((entry) => {
            if (entry.isDirectory) {
              return;
            }

            (entry as FileSystemFileEntry).file((file) => {
              counter++;

              if (!isAllowedFileType(file.name)) {
                setFormErrors((errors) => ({
                  ...errors,
                  file: `Only files with the type JPEG, E57 or LAS are allowed not: ${file.name}`,
                }));
                return;
              }

              tmpUserFiles.push({
                id: uuidv4(),
                file: file,
                isUploaded: false,
              });

              if (totalFiles == counter) {
                setUserFiles([...userFiles, ...tmpUserFiles]);
              }
            });
          });
        });
      }

      setUserFiles([...userFiles, ...tmpUserFiles]);
    }
  };

  const handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.target.files == null) return;

    setFormErrors((errors) => ({
      ...errors,
      file: "",
    }));

    if (event.target.files.length > 1) {
      setFormData({
        ...formData,
        datasetName: event.target.files[0].webkitRelativePath.split("/")[0],
      });
      const tmpFiles: UserFile[] = [];
      for (let i = 0; i < event.target.files.length; i++) {
        const fileName = event.target.files[i].name;
        if (!isAllowedFileType(fileName)) {
          setFormErrors((errors) => ({
            ...errors,
            file: `Only files with the type JPEG, E57 or LAS are allowed not: ${fileName}`,
          }));
          continue;
        }

        const newFile: UserFile = {
          id: uuidv4(),
          file: event.target.files[i],
          isUploaded: false,
        };

        tmpFiles.push(newFile);
      }
      setUserFiles([...userFiles, ...tmpFiles]);
    } else {
      const newFile: UserFile = {
        id: uuidv4(),
        file: event.target.files[0],
        isUploaded: false,
      };
      setUserFiles([...userFiles, newFile]);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        paddingY="3em"
        gap={4}
      >
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Box width="100px">
            <img
              src={CDN_IMAGES.logos("media_data_logos/md_uploader.svg")}
              height="100%"
              width="auto"
              alt="mdp-viewer"
            />
          </Box>
          <Typography variant="h4" fontWeight="bold">
            Welcome to Media Data Uploader
          </Typography>
          {/* <Typography variant="subtitle1">
            Bring your Media Data to iPEN
          </Typography> */}
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{ width: "500px" }}
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <Box display="flex" alignItems="start" gap={1} width={1}>
              <TextField
                id="dataset-name"
                fullWidth
                placeholder="Dataset name"
                disabled
                aria-label="Dataset Textfield"
                value={formData.datasetName}
                helperText={formErrors.datasetName}
                error={formErrors.datasetName != ""}
                inputProps={{ "data-testid": "dataset-textfield" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Folder />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="dataset-name-edit-button"
                        onClick={() => {
                          setShowDatesetNameDialog(true);
                        }}
                      >
                        <ModeEdit />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <IconTooltip text="Enter your Dataset Name to identify the uploaded file" />
            </Box>

            <Box
              sx={{ width: "500px" }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={5}
            >
              <Box display="flex" alignItems="start" gap={1} width={1}>
                <TextField
                  id="dataset-comment"
                  fullWidth
                  placeholder="Dataset comment"
                  aria-label="Comment Textfield"
                  value={formData.comment}
                  helperText={formErrors.comment}
                  error={formErrors.comment != ""}
                  onChange={handleCommentChange}
                  inputProps={{ "data-testid": "comment-textfield" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Notes />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconTooltip text="Enter a comment for the Dataset for additional information." />
              </Box>
            </Box>

            <Box
              sx={{ width: "500px" }}
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={5}
            >
              <Box display="flex" alignItems="start" gap={1} width={1}>
                <TextField
                  id="dataset-reference"
                  fullWidth
                  placeholder="Dataset reference"
                  aria-label="Reference Textfield"
                  value={formData.reference}
                  helperText={formErrors.reference}
                  error={formErrors.reference != ""}
                  onChange={handleReferenceChange}
                  inputProps={{ "data-testid": "reference-textfield" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AddLink />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconTooltip text="Enter a reference for the Dataset." />
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              width={1}
              flexDirection="column"
            >
              <DragAndDrop
                dragAndDropAriaLabel="Drag and Drop field to upload Media Data"
                userFiles={userFiles}
                handleFileSelect={handleFileSelect}
                handleDrop={handleDrop}
                handleDeleteFile={handleDeleteFile}
                helperText={formErrors.file}
                hasError={formErrors.file != ""}
                isUploading={isUploading}
                datasetName={formData.datasetName}
              />
            </Box>

            {isUploading && userFiles.length > 0 && (
              <Box
                display="flex"
                width={1}
                alignItems="center"
                border={1}
                py={2}
                borderRadius={1}
                borderColor={isUploading ? "secondary.main" : "success.main"}
              >
                <UploadFile
                  color={isUploading ? "secondary" : "success"}
                  sx={{ p: 2 }}
                />
                <Box display="flex" flexDirection="column" width={1} gap="3px">
                  <Typography variant="body1">
                    {formData.datasetName}
                  </Typography>
                  <Typography variant="body2" color="gray" component="div">
                    {/* {humanFileSize(uploadingFile?.size || 0)} •{" "} */}
                    {isUploading ? (
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        pl={1}
                        gap={1}
                      >
                        <CircularProgress
                          size={12}
                          color="secondary"
                          data-testid="upload-loading"
                        />{" "}
                        {progressText}
                      </Box>
                    ) : (
                      "Complete"
                    )}
                  </Typography>
                  <LinearProgress
                    sx={{ width: "90%", borderRadius: 2, opacity: 0.7 }}
                    variant="determinate"
                    color={isUploading ? "secondary" : "success"}
                    value={progress}
                    aria-label="Progressbar to display the upload progress"
                  />
                </Box>
              </Box>
            )}

            <Button
              type="submit"
              data-testid="submit"
              variant="contained"
              color="secondary"
              size="large"
              disabled={isUploading || !props.isOnline}
              startIcon={<CloudUploadOutlined />}
            >
              Upload
            </Button>
          </Box>
        </form>
      </Grid>

      <InfoSnackbar
        open={!props.isOnline}
        message={
          !props.isOnline && isUploading
            ? `Internet connection lost - ${errorMessage}`
            : "Internet connection lost"
        }
        severity="warning"
        hideDuration={0}
      />

      <InfoSnackbar
        open={showError}
        message={errorMessage}
        severity="error"
        hideDuration={10}
      />

      <UploadSuccessDialog
        datasetName={formData.datasetName}
        uploadSuccess={!showError && uploadSuccess}
        setUploadSuccess={uploadCleanup}
      />

      <DatasetNameDialog
        showDatasetNameDialog={showDatesetNameDialog}
        formData={formData}
        setShowDatasetNameDialog={setShowDatesetNameDialog}
        setFormData={setFormData}
      />
    </>
  );
};
