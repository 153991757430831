import React, { Snackbar, Alert } from "@mui/material";
import { FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";

interface InfoSnackbarProps {
  open: boolean;
  message: string;
  hideDuration: number;
  severity: "error" | "info" | "success" | "warning";
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    // border: "2px solid black",
    borderRadius: "4px",
  },
}));

const InfoSnackbar: FunctionComponent<InfoSnackbarProps> = (props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        autoHideDuration={props.hideDuration}
        open={props.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={props.severity}>{props.message}</Alert>
      </Snackbar>
    </div>
  );
};

export default InfoSnackbar;
